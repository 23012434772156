import { graphql, useStaticQuery } from 'gatsby';
import { ClaimOptionInfoCardContents } from './types';

type CsClaimOptionInfoCards = {
  csEmailUsFormCommon: {
    claims_or_my_policy: {
      claims_info_card: ClaimOptionInfoCardContents;
    };
  };
  csEmailUsFormClaimsSpecific: {
    make_claim: ClaimOptionInfoCardContents;
    track_claim: ClaimOptionInfoCardContents;
    ongoing_claim: ClaimOptionInfoCardContents;
  };
};

export const query = graphql`
  query {
    csEmailUsFormCommon {
      claims_or_my_policy {
        claims_info_card {
          heading
          icon {
            icon_code
          }
          info_card_text
        }
      }
    }
    csEmailUsFormClaimsSpecific {
      make_claim {
        heading
        icon {
          icon_code
        }
        info_card_text
      }
      ongoing_claim {
        heading
        icon {
          icon_code
        }
        info_card_text
      }
      track_claim {
        heading
        icon {
          icon_code
        }
        info_card_text
      }
    }
  }
`;

const useClaimOptionsInfoCards = (): CsClaimOptionInfoCards =>
  useStaticQuery<CsClaimOptionInfoCards>(query);

export default useClaimOptionsInfoCards;
