import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { graphql } from 'gatsby';
import React from 'react';
import Layout from 'components/Layout';
import { SessionExpiryOption } from 'components/SessionExpiry/SessionExpiryWrapper';
import EmailUsForm from 'forms/EmailUsForm';
import { PageTitle } from 'helpers/eventTracking';
import { CsHero } from 'types/contentStack';

type ContactProps = {
  data: {
    csEmailUsFormCommon: {
      meta_title: string;
      hero: CsHero;
      api_error_message: string;
      submit_button_text: string;
    };
  };
};

export const query = graphql`
  query {
    csEmailUsFormCommon {
      meta_title
      hero {
        heading
        subheading
      }
      api_error_message
      submit_button_text
    }
  }
`;

const Contact: React.FC<ContactProps> = ({
  data: {
    csEmailUsFormCommon: { hero, meta_title, api_error_message, submit_button_text },
  },
}) => (
  <Layout
    heading={hero.heading}
    subheading={hero.subheading}
    metaTitle={meta_title}
    pageTitle={PageTitle.EmailUsForm}
    sessionExpiryOption={SessionExpiryOption.NO_SESSION_EXPIRY}>
    <Grid alignLeft>
      <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6} mobile={4}>
        <EmailUsForm
          apiErrorMessage={api_error_message}
          submitButtonText={submit_button_text}
        />
      </GridItem>
    </Grid>
  </Layout>
);

export default Contact;
