import { emailApiService } from 'apiHelpers/apiService';
import { getEmailApiRecaptchaToken } from 'helpers/reCaptchaHelpers';

const emailClient = {
  sendEmail: async (
    destinationCode: string,
    emailAddress: string,
    fields: Record<string, string>
  ): Promise<void> => {
    const recaptchaToken = await getEmailApiRecaptchaToken();
    const body = {
      destinationCode,
      emailAddress,
      fields,
      recaptchaToken,
    };
    await emailApiService.post<void>('', body);
  },
};

export default emailClient;
