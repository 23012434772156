import { Image as ImageType } from '@rsa-digital/evo-shared-components/commonTypes/image';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import Modal from '@rsa-digital/evo-shared-components/components/Modal';
import React, { MouseEventHandler } from 'react';
import {
  Body,
  Heading,
  HeadingGrid,
  ImageGrid,
  PaddedGridItem,
  StyledImage,
} from './styles';

type ConfirmationModalProps = {
  title: string;
  body: string;
  image?: ImageType;
  onClose: MouseEventHandler;
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  title,
  body,
  image,
  onClose,
}) => (
  <Modal onClose={onClose} data-cy="Email Us confirmation">
    {image && (
      <ImageGrid>
        <GridItem desktop={2} tabletLandscape={2} tabletPortrait={2} mobile={2}>
          <StyledImage image={image} />
        </GridItem>
      </ImageGrid>
    )}
    <HeadingGrid>
      <PaddedGridItem desktop={4} tabletLandscape={4} tabletPortrait={4}>
        <Heading>{title}</Heading>
      </PaddedGridItem>
    </HeadingGrid>
    <Grid>
      <PaddedGridItem desktop={8} tabletLandscape={8} tabletPortrait={6}>
        <Body>{body}</Body>
      </PaddedGridItem>
    </Grid>
  </Modal>
);

export default ConfirmationModal;
