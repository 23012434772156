import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import Image from '@rsa-digital/evo-shared-components/components/Image';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledImage = styled(Image)`
  margin: ${spacing(3)} 0;
`;

export const Heading = styled.h2`
  ${fonts.headingSmall}
  text-align: center;
  margin: 0 0 ${spacing(2)};
`;

export const Body = styled.p`
  ${fonts.paragraph}
  text-align: center;
  margin: 0 0 ${spacing(2)};
`;

export const PaddedGridItem = styled(GridItem)`
  padding: 0 ${spacing(2)};

  ${mediaQuery.tabletPortrait`
    padding: 0;
  `}
`;

export const ImageGrid = styled(Grid)`
  && {
    margin-top: ${spacing(-1)};
    margin-bottom: ${spacing(1)};
  }
`;

export const HeadingGrid = styled(Grid)`
  && {
    margin-top: ${spacing(-1)};
  }
`;
