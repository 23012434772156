import { graphql, useStaticQuery } from 'gatsby';
import { PageTitle } from 'helpers/eventTracking';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import {
  CsQuestionWithPlaceholderAndReasonOptions,
  QuestionWithPlaceholderAndReasonOptions,
  ReasonOptions,
} from './types';

type CsGlobalDefaults = {
  csPetGlobalConfig: {
    select_field_default_placeholder: string;
  };
};

const globalConfigQuery = graphql`
  query {
    csPetGlobalConfig {
      select_field_default_placeholder
    }
  }
`;

type QuestionProcessor = {
  processQuestionWithPlaceholderAndReasonOptions: (
    csQuestion: CsQuestionWithPlaceholderAndReasonOptions
  ) => QuestionWithPlaceholderAndReasonOptions;
};

const useQuestionProcessorHelper = (): QuestionProcessor => {
  const { select_field_default_placeholder: defaultPlaceholderText } = useStaticQuery<
    CsGlobalDefaults
  >(globalConfigQuery).csPetGlobalConfig;

  const { processQuestion } = useQuestionProcessor(PageTitle.EmailUsForm);

  const processReasonOptions = (
    csQuestion: CsQuestionWithPlaceholderAndReasonOptions
  ): ReasonOptions[] =>
    csQuestion.answer_options.map((option) => ({
      name: option.name,
      value: option.value,
      displayAdditionalInformation: option.display_additional_information_field,
      displayPolicyUpdateDate: option.display_policy_update_date_field,
    }));

  const processQuestionWithPlaceholderAndReasonOptions = (
    csQuestion: CsQuestionWithPlaceholderAndReasonOptions
  ): QuestionWithPlaceholderAndReasonOptions => ({
    ...processQuestion(csQuestion),
    placeholder: csQuestion.main_details.placeholder || defaultPlaceholderText,
    options: processReasonOptions(csQuestion),
  });

  return {
    processQuestionWithPlaceholderAndReasonOptions,
  };
};

export default useQuestionProcessorHelper;
