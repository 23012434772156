import { FlexibleWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import { InfoCard } from '@rsa-digital/evo-shared-components/components/InfoCard';
import { InfoCardRichText } from '@rsa-digital/evo-shared-components/components/InfoCard/InfoCard/styles';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const InfoCardWithBottomMargin = styled(InfoCard)`
  margin-top: 0;
  margin-bottom: ${spacing(3)};
  padding: ${spacing(3)};

  h3 {
    ${fonts.headingXSmall};

    margin: 0;
  }

  ${InfoCardRichText} {
    ${fonts.paragraphLarge};
  }

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(4)};
  `}
`;

export const FlexibleWidthDividerWithMargin = styled(FlexibleWidthDivider)`
  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(6)};
  `}

  margin-top: ${spacing(8)};
`;
