import { Image } from '@rsa-digital/evo-shared-components/commonTypes/image';
import { graphql, useStaticQuery } from 'gatsby';
import { processImageAsset } from 'helpers/csTypeProcessors';
import { CsAsset } from 'types/contentStack';

type CsConfirmationModalData = {
  csEmailUsFormCommon: {
    confirmation_modal: {
      title: string;
      body: string;
      image: CsAsset | null;
    };
  };
};

type ConfirmationModalData = {
  title: string;
  body: string;
  image: Image | undefined;
};

const query = graphql`
  query {
    csEmailUsFormCommon {
      confirmation_modal {
        title
        body
        image {
          ...CsAsset
        }
      }
    }
  }
`;

const useConfirmationModal = (): ConfirmationModalData => {
  const {
    csEmailUsFormCommon: { confirmation_modal },
  } = useStaticQuery<CsConfirmationModalData>(query);

  return {
    title: confirmation_modal.title,
    body: confirmation_modal.body,
    image: processImageAsset(confirmation_modal.image),
  };
};

export default useConfirmationModal;
