import { dateValueToUtcDate } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import {
  dateValueLessThanOrEqualTo,
  dateValueValid,
  dateValueValidDay,
  dateValueValidMonth,
  dateValueValidYear,
  lengthGreaterOrEqualTo,
  matchesRegex,
  required,
  requiredDateValue,
  validateIf,
} from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import { isAfterMinValidDate } from 'helpers/ageHelpers';
import { EMAIL_REGEX, PHONE_REGEX, POSTCODE_REGEX } from 'helpers/regexes';
import { CsErrorsMissingOnly } from 'types/contentStack';
import { ClaimOrPolicyOption, EmailUsFormData } from './types';

type CsEmailUsErrorMessages = {
  csEmailUsFormCommon: {
    name: CsErrorsMissingOnly;
    date_of_birth: {
      error_messages: {
        missing: string;
        invalid_day: string;
        invalid_month: string;
        year_too_short: string;
        invalid_date: string;
        date_in_future: string;
        is_before_1900: string;
      };
    };
    email_address: {
      error_messages: {
        missing: string;
        too_short: string;
        invalid_email_address_format: string;
      };
    };
    postcode: {
      error_messages: {
        missing: string;
        too_short: string;
        invalid_postcode_format: string;
      };
    };
    telephone: {
      error_messages: {
        missing: string;
        too_short: string;
        invalid_phone_number_format: string;
      };
    };
    policy_contact_reason: CsErrorsMissingOnly;
    payment: CsErrorsMissingOnly;
  };
  csEmailUsFormPolicySpecific: {
    policy_contact_reason: CsErrorsMissingOnly;
    policy_update_date: {
      error_messages: {
        missing: string;
        invalid_day: string;
        invalid_month: string;
        invalid_date: string;
        year_too_short: string;
      };
    };
  };
  csEmailUsFormClaimsSpecific: {
    claim_options: {
      error_messages: {
        missing: string;
      };
    };
  };
};

const query = graphql`
  query {
    csEmailUsFormCommon {
      name {
        error_messages {
          missing
        }
      }
      date_of_birth {
        error_messages {
          missing
          invalid_day
          invalid_month
          year_too_short
          invalid_date
          date_in_future
          is_before_1900
        }
      }
      email_address {
        error_messages {
          missing
          too_short
          invalid_email_address_format
        }
      }
      postcode {
        error_messages {
          missing
          too_short
          invalid_postcode_format
        }
      }
      telephone {
        error_messages {
          missing
          too_short
          invalid_phone_number_format
        }
      }
      payment {
        error_messages {
          missing
        }
      }
    }
    csEmailUsFormPolicySpecific {
      policy_contact_reason {
        error_messages {
          missing
        }
      }
      policy_update_date {
        error_messages {
          missing
          invalid_day
          invalid_month
          invalid_date
          year_too_short
        }
      }
    }
    csEmailUsFormClaimsSpecific {
      claim_options {
        error_messages {
          missing
        }
      }
    }
  }
`;

const useEmailUsRules = (
  enquiryType: string,
  displayPolicyUpdateDate: boolean | undefined
): ValidationRules<EmailUsFormData> => {
  const errorMessages = useStaticQuery<CsEmailUsErrorMessages>(query);

  /* If either of the reason field (for enquiring about policy) or the claimOption field (for enquiring about claims),
   * have not been answered, the user will not be able to see the rest of the form fields and the submit button
   *
   * Because there could be a change in the future, where the submit button is visible and pressed without answering
   * them, we apply a validate if to them
   *
   * If this does happen, then we'd also need to add validate if conditions on the other fields, since they're only
   * visible if the reason or claimOption fields have been answered first
   */
  return {
    claimOption: validateIf(() => enquiryType === ClaimOrPolicyOption.Claim, [
      required(
        errorMessages.csEmailUsFormClaimsSpecific.claim_options.error_messages.missing
      ),
    ]),
    reason: validateIf(() => enquiryType === ClaimOrPolicyOption.Policy, [
      required(
        errorMessages.csEmailUsFormPolicySpecific.policy_contact_reason.error_messages
          .missing
      ),
    ]),
    name: [required(errorMessages.csEmailUsFormCommon.name.error_messages.missing)],
    dob: [
      requiredDateValue(
        errorMessages.csEmailUsFormCommon.date_of_birth.error_messages.missing
      ),
      dateValueValidDay(
        errorMessages.csEmailUsFormCommon.date_of_birth.error_messages.invalid_day
      ),
      dateValueValidMonth(
        errorMessages.csEmailUsFormCommon.date_of_birth.error_messages.invalid_month
      ),
      dateValueValidYear(
        errorMessages.csEmailUsFormCommon.date_of_birth.error_messages.year_too_short
      ),
      dateValueValid(
        errorMessages.csEmailUsFormCommon.date_of_birth.error_messages.invalid_date
      ),
      dateValueLessThanOrEqualTo(
        new Date(),
        errorMessages.csEmailUsFormCommon.date_of_birth.error_messages.date_in_future
      ),
      {
        validityCondition: (value) => isAfterMinValidDate(dateValueToUtcDate(value)),
        errorMessage:
          errorMessages.csEmailUsFormCommon.date_of_birth.error_messages.is_before_1900,
      },
    ],
    emailAddress: [
      required(errorMessages.csEmailUsFormCommon.email_address.error_messages.missing),
      lengthGreaterOrEqualTo(
        4,
        errorMessages.csEmailUsFormCommon.email_address.error_messages.too_short
      ),
      matchesRegex(
        EMAIL_REGEX,
        errorMessages.csEmailUsFormCommon.email_address.error_messages
          .invalid_email_address_format
      ),
    ],
    postcode: [
      required(errorMessages.csEmailUsFormCommon.postcode.error_messages.missing),
      lengthGreaterOrEqualTo(
        5,
        errorMessages.csEmailUsFormCommon.postcode.error_messages.too_short
      ),
      matchesRegex(
        POSTCODE_REGEX,
        errorMessages.csEmailUsFormCommon.postcode.error_messages.invalid_postcode_format
      ),
    ],
    telephone: [
      required(errorMessages.csEmailUsFormCommon.telephone.error_messages.missing),
      lengthGreaterOrEqualTo(
        11,
        errorMessages.csEmailUsFormCommon.telephone.error_messages.too_short
      ),
      matchesRegex(
        PHONE_REGEX,
        errorMessages.csEmailUsFormCommon.telephone.error_messages
          .invalid_phone_number_format
      ),
    ],
    reasonPolicyUpdateDate: validateIf(() => !!displayPolicyUpdateDate, [
      requiredDateValue(
        errorMessages.csEmailUsFormPolicySpecific.policy_update_date.error_messages
          .missing
      ),
      dateValueValidDay(
        errorMessages.csEmailUsFormPolicySpecific.policy_update_date.error_messages
          .invalid_day
      ),
      dateValueValidMonth(
        errorMessages.csEmailUsFormPolicySpecific.policy_update_date.error_messages
          .invalid_month
      ),
      dateValueValid(
        errorMessages.csEmailUsFormPolicySpecific.policy_update_date.error_messages
          .invalid_date
      ),
      dateValueValidYear(
        errorMessages.csEmailUsFormPolicySpecific.policy_update_date.error_messages
          .year_too_short
      ),
    ]),
    payment: [required(errorMessages.csEmailUsFormCommon.payment.error_messages.missing)],
  };
};

export default useEmailUsRules;
